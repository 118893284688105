import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import mapSectionsToComponent from '../utils/mapSectionsToComponent'
import SectionComponents from '../components/SectionComponents'
import { SiteConfig } from '../types/ContentBuilder'
import SubscribeForm from '../components/SubscribeForm'

const defaultTheme = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

interface IndexProps {
    pageContext: {
        content: []
        title?: string
        config?: SiteConfig
    }
}

export default ({ pageContext }: IndexProps) => {
    const sections = mapSectionsToComponent(pageContext.content)
    return (
        <ThemeProvider value={defaultTheme}>
            <Layout>
                <SEO title={pageContext.title} />
                <SectionComponents
                    sections={sections}
                    config={pageContext.config}
                />
                <SubscribeForm
                    theme={defaultTheme}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}
